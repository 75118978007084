span.__editor_AlignLeft {
  text-align: left;
  display: block;
}

span.__editor_AlignRight {
  text-align: right;
  display: block;
}

span.__editor_AlignCenter {
  text-align: center;
  display: block;
}

span.__editor_AlignJustify {
  text-align: justify;
  display: block;
}
