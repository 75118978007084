@mixin icon($color) {
  font-size: 20px;
  font-weight: $bold;
  color: $color;
}

@mixin heading($size) {
  font-size: $size;
  line-height: $size * 1.5;
  font-weight: $bold;
}

@mixin text($size) {
  font-size: $size;
  line-height: $size * 1.5;
  font-weight: $regular;
}
