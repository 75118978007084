span.__editor_StyleButton {
  display: inline-block;
  padding: 0.5em 1em;
  margin: 0.5em;
  border-radius: 0.5em;
  background-color: var(--DtxPrimarySiteColour) !important;
  color: var(--DtxTextColour);
  text-decoration: none;
}

.BgColourPrimary span.__editor_StyleButton {
  background-color: color-mix(in srgb, var(--DtxPrimarySiteColour) 70%, #000000) !important;
}

.container-fluid .DTX_CONTENT_ROW {
  margin-left: calc(var(--DtxGutterWidth) / -2);
  margin-right: calc(var(--DtxGutterWidth) / -2);
}
