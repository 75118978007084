* {
  box-sizing: border-box;
}

html {
  scroll-behavior: auto !important;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  box-sizing: border-box;
  text-decoration: none !important;
  outline: none;

  &.modal-open {
    position: relative;
    height: 100vh;
    padding-right: 0 !important;
  }
}


h1 {
  @include heading(52px);
}

h2 {
  @include heading(44px);
}

h3 {
  @include heading(38px);
}

h4 {
  @include heading(32px);
}

h5 {
  @include heading(28px);
}

h6 {
  @include heading(24px);
}

p,
li {
  @include text(18px);
}

a {
  @include text(18px);
  color: var(--DtxLinkColour);
}

@media only screen and (max-width: 767px) {
  h1 {
    @include heading(30px);
  }
  h2 {
    @include heading(26px);
  }
  h3 {
    @include heading(22px);
  }
  h4 {
    @include heading(20px);
  }
  h5 {
    @include heading(18px);
  }
  h6 {
    @include heading(16px);
  }
  p,
  li {
    @include text(14px);
  }
  a {
    @include text(14px);
  }
}


.image {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;

  &.img-cover {
    width: 100%;
    height: 100%;
    flex: 1;
    object-fit: cover;
  }
}

// Sidebar removal error hotfix
.p-component-overlay-leave.p-component-overlay-enter {
  display: none;
}

.DTX_CONTENT_ANCHOR {
  visibility: hidden;
  height: 0;
  display: block;
  padding-top: 120px;
  margin-top: -120px;
}