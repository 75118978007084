.aimg-scale-10 app-images, .AImgScale10 app-images {
  width: 10% !important;
}

.aimg-scale-20 app-images, .AImgScale20 app-images {
  width: 20% !important;
}

.aimg-scale-25 app-images, .AImgScale25 app-images {
  width: 25% !important;
}

.aimg-scale-30 app-images, .AImgScale30 app-images {
  width: 30% !important;
}

.aimg-scale-33 app-images, .AImgScale33 app-images {
  width: 33% !important;
}

.aimg-scale-40 app-images, .AImgScale40 app-images {
  width: 40% !important;
}

.aimg-scale-50 app-images, .AImgScale50 app-images {
  width: 50% !important;
}

.aimg-scale-60 app-images, .AImgScale60 app-images {
  width: 60% !important;
}

.aimg-scale-67 app-images, .AImgScale67 app-images {
  width: 67% !important;
}

.aimg-scale-70 app-images, .AImgScale70 app-images {
  width: 70% !important;
}

.aimg-scale-75 app-images, .AImgScale75 app-images {
  width: 75% !important;
}

.aimg-scale-80 app-images, .AImgScale80 app-images {
  width: 80% !important;
}

.aimg-scale-90 app-images, .AImgScale90 app-images {
  width: 90% !important;
}

.img-scale-10 img, ImgScale10 img {
  width: 10% !important;
}

.img-scale-20 img, ImgScale20 img {
  width: 20% !important;
}

.img-scale-25 img, ImgScale25 img {
  width: 25% !important;
}

.img-scale-30 img, ImgScale30 img {
  width: 30% !important;
}

.img-scale-40 img, ImgScale40 img {
  width: 40% !important;
}

.img-scale-50 img, ImgScale50 img {
  width: 50% !important;
}

.img-scale-60 img, ImgScale60 img {
  width: 60% !important;
}

.img-scale-70 img, ImgScale70 img {
  width: 70% !important;
}

.img-scale-75 img, ImgScale75 img {
  width: 75% !important;
}

.img-scale-80 img, ImgScale80 img {
  width: 80% !important;
}

.img-scale-90 img, ImgScale90 img {
  width: 90% !important;
}
