@import '../variable/font-weight.scss';

.font-weight-force-medium, .FontWeightForceMedium {
  font-weight: $medium !important;
}

.font-weight-force-regular, .FontWeightForceRegular {
  font-weight: $regular !important;
}

.font-weight-force-light, .FontWeightForceLight {
  font-weight: $light !important;
}

body {
  color: var(--DtxTextColour);
}
