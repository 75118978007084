.BgColourPrimary *, .BgColourPrimary {
  background-color: var(--DtxPrimarySiteColour) !important;
}

.BgColourSecondary *, .BgColourSecondary {
  background-color: var(--DtxSecondarySiteColour) !important;
}

.BgColourTertiary *, .BgColourTertiary {
  background-color: var(--DtxTertiarySiteColour) !important;
}

.TextColourWhite *, .TextColourWhite {
  color: white !important;
}

.TextColourBlack *, .TextColourBlack {
  color: black !important;
}

.TextColourPrimary *, .TextColourPrimary {
  color: var(--DtxPrimarySiteColour) !important;
}

.TextColourSecondary *, .TextColourSecondary {
  color: var(--DtxSecondarySiteColour) !important;
}

.TextColourTertiary *, .TextColourTertiary {
  color: var(--DtxTertiarySiteColour) !important;
}

.TileBgColourPrimary div {
  background-color: var(--DtxPrimarySiteColour) !important;
}

.TileBgColourSecondary div {
  background-color: var(--DtxSecondarySiteColour) !important;
}

.TileBgColourTertiary div {
  background-color: var(--DtxTertiarySiteColour) !important;
}

.TileShadowColourPrimary {
  --DtxCardShadowColour: var(--DtxPrimarySiteColour) !important;
}

.TileShadowColourSecondary {
  --DtxCardShadowColour: var(--DtxSecondarySiteColour) !important;
}

.TileShadowColourTertiary {
  --DtxCardShadowColour: var(--DtxTertiarySiteColour) !important;
}
