.AnimateCounterScroll  {
    visibility: hidden;
}

.AnimateDelay1 {
    animation-delay: 1s;
}

.AnimateDelay2 {
    animation-delay: 2s;
}

.AnimateDelay3 {
    animation-delay: 3s;
}

.AnimationDtx {
    animation-fill-mode: forwards;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    opacity: 0;
}


.AnimateLineAcrossLeft.StartAnimation {    
    @extend .AnimationDtx;
    animation-name: anim-lineAcrossLeft;    
}

.AnimateLineAcrossRight.StartAnimation {    
    @extend .AnimationDtx;
    animation-name: anim-lineAcrossRight;    
}

.AnimateLineAcrossLeft, .AnimateLineAcrossRight, .AnimateLineDown, .AnimateLineUp, .AnimateTextInOut {
    opacity: 0;
}

.AnimateLineDown.StartAnimation {
    @extend .AnimationDtx;
    animation-name: anim-lineDown;
}

.AnimateLineUp.StartAnimation {
    @extend .AnimationDtx;
    animation-name: anim-lineUp;
}

.AnimateTextInOut.StartAnimation {
    @extend .AnimationDtx;
    animation-name: anim-textInOut;
    animation-timing-function: ease-in-out;
}


@keyframes anim-lineAcrossLeft {
    0% {
        opacity: 0;
        transform: translateX(-70%);
      }
      20% {
        opacity: 0.1;
      }
      20% {
        opacity: 0.3;
      }
      50% {
        opacity: 0.6;
        transform: translateX(0%);
      }
      100% {
        opacity: 1;
        transform: translateX(0%);
      }
}

@keyframes anim-lineAcrossRight {
    0% {
        opacity: 0;
        transform: translateX(70%);
      }
      20% {
        opacity: 0.1;
      }
      20% {
        opacity: 0.3;
      }
      50% {
        opacity: 0.6;
        transform: translateX(0%);
      }
      100% {
        opacity: 1;
        transform: translateX(0%);
      }
}

@keyframes anim-lineDown {
    0% {
        opacity: 0;
        transform: translateY(-60%);
      }
      20% {
        opacity: 0.1;
      }
      20% {
        opacity: 0.3;
      }
      50% {
        opacity: 0.6;
        transform: translateY(0%);
      }
      100% {
        opacity: 1;
        transform: translateY(0%);
      }
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(60%);
      }
      20% {
        opacity: 0.1;
      }
      20% {
        opacity: 0.3;
      }
      50% {
        opacity: 0.6;
        transform: translateY(0%);
      }
      100% {
        opacity: 1;
        transform: translateY(0%);
      }
}

@keyframes anim-textInOut {
    0% {
      transform: scale(0.10);
      opacity: 0;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    75% {
      transform: scale(1.5);
      opacity: 0.75;
      text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
    100% {
      /* animate nothing to add pause at the end of animation */
      transform: scale(1);
      opacity: 1;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
    }
  }
