span.__editor_ColourPrimary {
  color: var(--DtxPrimarySiteColour);
}

span.__editor_ColourSecondary {
  color: var(--DtxSecondarySiteColour);
}

span.__editor_ColourTertiary {
  color: var(--DtxTertiarySiteColour);
}
