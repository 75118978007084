.p-button {
  padding: revert-layer;
  border-radius: revert-layer;
  font-size: revert-layer;
}

.p-scrolltop {
  border-radius: revert-layer;
}
.p-button {
  .p-button-label {
    margin-bottom: 0;
  }
}

.p-steps {
  ul {
    padding-left: 0;
  }
  .p-steps-number,
  .p-steps-title {
    margin-bottom: 0;
  }
}

.p-accordion-tab {
  a {
    text-decoration: none;
  }
}

.p-confirm-dialog {
  .p-dialog-footer {
    .p-button {
      font-size: 0.875rem;
    }
  }
}

.p-element {
  span {
    margin-bottom: 0;
  }
}

.p-autocomplete,
.p-dropdown,
.p-multiselect,
.p-tieredmenu,
.p-menubar {
  ul {
    padding-left: 0;
    margin-bottom: 0;
  }
}

li.p-dropdown-item {
  font-size: revert-layer;
  line-height: revert-layer;
  color: revert-layer;
  font-weight: revert-layer;
  text-align: start;
}

.tw-container {
  width: 100%;
}
@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}
