@tailwind utilities;
@import 'assets/scss/__editor/alignment.scss';
@import 'assets/scss/__editor/colour.scss';
@import 'assets/scss/__editor/size';
@import 'assets/scss/__editor/style.scss';
@import 'assets/scss/dynatexCustomClasses/colour.scss';
@import 'assets/scss/dynatexCustomClasses/imageScale.scss';
@import 'assets/scss/dynatexCustomClasses/text.scss';
@import 'assets/scss/dynatexCustomClasses/wrapping.scss';
@import 'assets/scss/dynatexCustomClasses/util.scss';
@import 'assets/scss/dynatexCustomClasses/animation.scss';
@import 'assets/scss/static/overrides';
@import 'assets/scss/import/variable.scss';
@import 'assets/scss/import/functions.scss';
@import 'assets/scss/import/mixin.scss';
@import 'assets/scss/import/common.scss';
@import 'assets/scss/import/elements.scss';
@import 'assets/scss/import/primeng-custom.scss';
@import 'primeng/resources/themes/lara-light-green/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
@import '@fortawesome/fontawesome-free/css/regular.min.css';
@import '@fortawesome/fontawesome-free/css/solid.min.css';
@import 'swiper/swiper-bundle.css';
