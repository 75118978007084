.p-progress-spinner {
  display: inline-flex !important;
  background: #0003;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100vh !important;

  &::before {
    padding-top: 0 !important;
  }

  .p-progress-spinner-svg {
    height: 100px;
    width: 100px;
    position: initial;
  }
}
